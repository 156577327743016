"use strict";
const sidebarToggle = document.body.querySelector('#sidebarToggle');
if (sidebarToggle) {
    if (localStorage.getItem('sidebar-toggle') === 'true') {
        document.body.classList.toggle('sidenav-toggled');
    }
    sidebarToggle.addEventListener('click', event => {
        event.preventDefault();
        document.body.classList.toggle('sidenav-toggled');
        localStorage.setItem('sidebar-toggle', String(document.body.classList.contains('sidenav-toggled')));
    });
}
