import { Naja } from '../plugin/Nette';
import { Toast } from 'bootstrap';
const showNotifications = () => {
    Array.from(document.querySelectorAll('#notifications > .toast:not(.fade)')).forEach((node) => Toast.getOrCreateInstance(node).show());
};
Naja.addEventListener('success', ({ detail }) => {
    if (detail.payload.notifications) {
        const container = document.getElementById('notifications');
        const toastEl = new DOMParser().parseFromString(detail.payload.notifications, 'text/html');
        if (toastEl && container) {
            container.prepend(...toastEl.body.children);
            showNotifications();
        }
    }
});
window.showNotifications = showNotifications;
