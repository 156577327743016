// @ts-ignore
import { LiveForm, Nette } from 'live-form-validation';
import naja from 'naja';
window.LiveForm = LiveForm;
window.Nette = Nette;
window.LiveForm.setOptions({
    messageErrorPrefix: '',
    showMessageClassOnParent: 'form-control',
    controlErrorClass: 'is-invalid',
    messageErrorClass: 'invalid-feedback',
    messageTag: 'span',
});
naja.formsHandler.netteForms = Nette;
const Naja = naja;
export { Nette, Naja };
