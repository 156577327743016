import { Alert, Dropdown, Modal, Tooltip } from 'bootstrap';
import { Naja } from './Nette';
const BootstrapInit = () => {
    Array.from(document.querySelectorAll('.modal')).forEach((node) => Modal.getOrCreateInstance(node));
    Array.from(document.querySelectorAll('.alert')).forEach((node) => Alert.getOrCreateInstance(node));
    Array.from(document.querySelectorAll('[data-bs-toggle="dropdown"]')).forEach((node) => Dropdown.getOrCreateInstance(node));
    Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]')).forEach((node) => Tooltip.getOrCreateInstance(node));
    // Remove tooltips after ajax redraw components
    Array.from(document.getElementsByClassName('tooltip')).forEach(node => node.remove());
};
BootstrapInit();
Naja.addEventListener('complete', BootstrapInit);
